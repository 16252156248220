import React from 'react'
import { Layout } from 'react-theme-bootstrap'
import Helpers from '@components/Helpers/Helpers'
import Dropdown from 'react-bootstrap/Dropdown'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Input } from '@components/Form/Form'

const Theme = ({ children, page, title, showBread }) => {
    const store = useSelector(store => store.session)

    const allPermissions = store.permissions.map(p => {
        return {
            parent: {
                name_tag: p.parent.description
            },
            childrens: p.childrens.map(c1 => {
                return {
                    parent: {
                        name_tag: c1.parent.description,
                        icon_tag: c1.parent.icon
                    },
                    childrens: c1.childrens.map(c2 => {
                        return {
                            name_tag: c2.description,
                            href_tag: c2.a_href
                        }
                    })
                }
            })
        }
    })
    console.log(allPermissions)

    return <Layout
        page={page}
        title={title}
        companyName={Helpers.config.companyName()}
        brand={Helpers.config.resourcesUrl({ url: '/images/dashboard/brand-dashboard.png' })}
        openNavDesktop
        permissions={allPermissions}
        /* showBread={showBread} */
        /* iconsLeft={[
            {
                type: 'html', content: <Input classNameParent="" size="" placeholder="Búsqueda..." />,
                style: {
                    paddingLeft: 20,
                    minWidth: 200
                },
                noHover: true,
                hideOnMobile: true
            }
        ]} */
        iconsRight={[
            {
                type: 'image',
                content: Helpers.config.resourcesUrl({ url: store.file_photo_user }),
                styleImage: {
                    borderRadius: 20
                },
                dropdown: ({ custom }) => <Dropdown>
                    <Dropdown.Toggle as={custom} />
                    <Dropdown.Menu>
                        <Dropdown.Item className="d-flex" onClick={_ => {
                            localStorage.clear()
                            window.location.href = Helpers.config.appUrl({ url: '/' });
                        }}>
                            <i className="fa fa-arrow-left mr-2 align-self-center"></i>
                            <span>Salir</span>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            },
            {
                type: 'html', content: <span>{store.username}</span>,
                style: {
                    minWidth: 100,
                    fontSize: 15
                },
                noHover: true,
                hideOnMobile: true
            },
        ]}
        Link={Link}
        linkTo={({ url }) => Helpers.config.appUrl({ url })}
        linkToBrand={Helpers.config.appUrl()}
        showLevelOne={false}
        desktopExpand={false}
        showBread={false}
        contentBeforeNavigation={<>
            <div className="d-flex flex-column align-items-center">
                <img src={Helpers.config.resourcesUrl({ url: '/images/dashboard/brand-dashboard-icsitum.png' })} alt="Logo" style={{
                    maxWidth: 150
                }} />
                <div className="d-flex mt-3">
                    <div className="mr-2">
                        <img src={Helpers.config.resourcesUrl({ url: store.file_photo_user })} alt="Perfil" style={{
                            maxWidth: 50,
                            borderRadius: 10
                        }} />
                    </div>
                    <div className="d-flex flex-column mt-2 mb-4">
                        <strong className="text-primary">{store.full_name}</strong>
                        <span style={{ fontSize: 10 }}>{store.description_profile}</span>
                    </div>
                </div>
            </div>
        </>}
        hideTop
    >
        {children}
    </Layout>
}

Theme.defaultProps = {
    showBread: true,
    titleTop: ''
}

export default Theme